














































































































































































































































































































































// ------20200409-----
@import url('../../../assets/css/login.less');
.registBox {
	// padding-bottom: 1.42rem;
	.registBody {
		padding: 0 0.3rem;
		background: #ffffff;
		.headIcon {
			img {
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				margin: 0 auto;
			}
		}
		.agreelaw {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: 400;
			color: #c3c5c8;
			padding-top: 0.25rem;
			.mainColor1{
				color: #90C4FF;
				// border-bottom: 1px solid #90C4FF;
			}
		}
		.tologin {
			font-size: 0.28rem;
			text-align: center;
			color: #999;
			padding-bottom: 0.3rem;
		}
	}
	.registerPage {
		color: #FFFFFF;
	    padding: 0.2rem 0.2rem 0.4rem;
	}
	.titleBtnStyle {
	    background: #FC9216;
	    border: 0px;
	    border-radius: 6px;
	    width: 1.6rem;
	    float: right;
	}
}

/* 我同意字体在有的分辨率下显示不全的问题 */

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;