.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.titleList {
  font-weight: bold;
  margin: 0 0.3rem;
}
.billNum {
  text-align: center;
  padding: 0.4rem 0rem 0.6rem 0rem;
}
.billNum p {
  font-size: 0.27rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.billNum span {
  display: block;
  margin-top: 0.2rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fff;
}
.cumulative {
  background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
  background-size: 100% 100%;
}
.today {
  background: url(../../assets/imgs/bill/jryl.png) no-repeat;
  background-size: 100% 100%;
}
.bodySpanStyle {
  color: #333;
  font-size: 0.32rem;
  line-height: 0.45rem;
  margin-bottom: 0.06rem;
}
.bodySpanStyle.num {
  color: #42854a;
  font-size: 0.4rem;
  font-family: Bahnschrift;
}
.bodyLiStyle {
  color: #666666;
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.bodyLiStyle.right {
  width: 2.4rem;
}
.bodyDivStyle {
  margin: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  border-bottom: 1px solid #EDEDED;
}
.bodyDivStyle:last-child {
  border-bottom: none;
}
.titleVanRowDivStyle {
  width: 100%;
  background: #ffffff;
}
.communityInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  background: #FFFFFF;
  box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
  border-radius: 0.12rem;
  margin: 0 0.3rem 0.5rem;
  position: relative;
  z-index: 2;
}
.communityInfo .list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.communityInfo .list .item {
  color: #666666;
  font-size: 0.28rem;
  line-height: 0.4rem;
  width: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communityInfo .list .item .yen {
  color: #FF9872;
  font-size: 0.52rem;
  font-family: Bahnschrift;
  line-height: 0.6rem;
  margin-bottom: 0.1rem;
}
.communityInfo .list .item .i_right {
  margin-left: 0.32rem;
}
.communityInfo .list .item .i_right img {
  width: 0.15rem;
  height: 0.26rem;
}
.communityInfo .list .split {
  width: 1px;
  height: 0.6rem;
  background: #E7E7E7;
}
.caption {
  font-size: 0.32rem;
  font-weight: bold;
  color: #373737;
  margin-bottom: 0.25rem;
}
.layoutBox {
  padding-top: 1.6rem;
  margin-top: -1.6rem;
  position: relative;
  z-index: 1;
}
