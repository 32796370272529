














































































































































































































































































.journalBox {
	// width: 100%;
	// height: 100%;
	// overflow-y: auto;

	.journalList {
		.listBox {
			padding: 0.55rem 0;
			margin: 0 0.4rem;
			border-bottom: 1px solid #eee;
		}
		.journalName {
			font-size: 0.35rem;
			span {
				margin-left: 0.2rem;
			}
		}
		.journalDesc {
			font-size: 0.3rem;
			color: #9e9d9d;
			margin-top: 0.15rem;
			line-height: 0.5rem;
		}
		.timeBox {
			color: #999;
			font-size: 0.3rem;
			text-align: center;
			line-height: 0.45rem;
		}
	}

	.dingDanStyle {
		margin: 0.3rem;
		color: #999999;
	}

	.chongZhiStyle {
		width: 3.4rem;
	}

	.queDingStyle {
		background: #ecbb34;
		border: 0;
		width: 3.4rem;
	}

	.tanChuCeng {
		padding: 0.3rem;
	}

	/*.bodySpanStyle {*/
	/*    color: #ecbb34;*/
	/*}*/

	/*.bodyLiStyle {*/
	/*    margin: 0.1rem;*/
	/*    color: #86878d;*/
	/*}*/

	/*.bodyDivStyle {*/
	/*    border-radius: 5px;*/
	/*    background: #ffffff;*/
	/*    margin: 0.3rem;*/
	/*    padding: 0.2rem;*/
	/*}*/
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;