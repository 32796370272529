.commonHeader {
  position: relative;
  z-index: 100;
  padding: 0 0.3rem;
  margin-bottom: 0.3rem;
  background-image: url(../assets/imgs/common_bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 3.5rem;
}
.commonHeader .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.8rem;
}
.commonHeader .menuBtn {
  position: absolute;
  left: 0;
  top: 0;
}
.commonHeader .menuBtn .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHeader .menuBtn .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHeader .title {
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
}
.commonHeader .langBtn {
  position: absolute;
  right: 0;
  top: 0;
}
.commonHeader .langBtn .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHeader .langBtn .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHeader .tabTit {
  color: #FFFFFF;
  font-size: 0.36rem;
  display: flex;
  padding: 0 2rem;
  justify-content: space-around;
}
.commonHeader .tabTit .active {
  font-size: 0.4rem;
  font-weight: bold;
  border-bottom: 1px solid #FFFFFF;
}
