.change {
  display: flex;
  width: 100%;
  font-size: 0.2rem;
  margin-top: 0.16rem;
}
.itemChange {
  width: 1.4rem;
  color: #888;
}
.itemChange .v {
  margin-left: 3px;
  font-weight: 500;
  font-size: 0.2rem;
  color: #333;
  font-family: Bahnschrift;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.title {
  display: inline;
  border-radius: 5px;
  margin-left: 0.3rem;
  color: #fb8b0d;
  font-size: 0.26rem;
}
.spe {
  display: inline-block;
  height: 0.2rem;
  border-right: 1px solid #e7e7e7;
}
.quantification .searchBox {
  margin: 0 0.3rem 0.4rem;
}
.quantification .searchRelative {
  position: relative;
}
.quantification .searchRelative .searchButton {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.31rem;
  font-weight: 600;
  color: #373737;
  width: 1.6rem;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  background: #f0f0f0;
  border-radius: 0.5rem;
}
.quantification .filterBox {
  padding: 0.2rem 0.15rem;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px rgba(202, 202, 202, 0.3);
  padding: 0 0.3rem 0.3rem;
}
.quantification .menuLine {
  padding: 8px 0.8rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quantification .menuLine .menuName {
  font-size: 0.24rem;
  color: #373737;
  display: inline-block;
  margin-top: 0.03rem;
}
.quantification .menuLine .menuBlock {
  font-size: 0.28rem !important;
  border-radius: 4px;
  padding: 3px 6px;
  width: auto !important;
  background-color: #f5f5f5;
}
.quantification .menuLine .menuItem {
  text-align: center;
  width: 25%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.quantification .menuLine .menuItem:last-child {
  margin-right: 0;
}
.quantification .menuLine .menuItem.on {
  font-weight: 700;
  font-size: 0.32rem;
  color: #3893fb;
}
.quantification .menuLine .menuItem1 {
  text-align: center;
  width: 25%;
  padding-bottom: 0.2rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.quantification .menuLine .menuItem1:last-child {
  margin-right: 0;
  padding-bottom: 0;
}
.quantification .menuLine .menuItem1.on {
  font-weight: 700;
  font-size: 0.32rem;
  color: #3893fb;
}
.quantification .menuLine .menuBlock.on {
  background-color: #f3f8fe;
}
.quantification .menuLine .dropdownBtn {
  color: #3893fb;
  font-size: 0.32rem;
}
.quantification .listQuan {
  padding-bottom: 1.2rem;
  margin: 0 0.245rem 0;
}
.quantification .listQuan li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.39rem;
  border-bottom: 1px solid #e4e7ed;
}
.quantification .listQuan li .name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #181818;
}
.quantification .listQuan li .checkbox {
  display: inline-block;
  vertical-align: -0.05rem;
  margin-right: 0.16rem;
}
.list {
  margin: 0 0.3rem;
}
.tan {
  margin-top: 0.3rem;
}
.tan .tan_header {
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
}
.tan .tan_header .tan_header_biaozi {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #373737;
}
.tan .tan_header .tan_header_txt {
  font-family: PingFang SC;
  margin: 0 auto;
  color: #373737;
  font-size: 0.24rem;
}
.tan .tan_content {
  padding: 0 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.tan .tan_content .tan_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tan .tan_content .tan_item .img {
  width: 0.9rem;
  height: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
}
.tan .tan_content .tan_item .img img {
  width: 100%;
  height: 100%;
}
.tan .tan_content .tan_item .tan_item_txt {
  font-weight: 600;
  margin-top: 0.2rem;
  width: 100%;
  color: #666666;
  font-size: 0.24rem;
  text-align: center;
}
.setResultWin {
  width: 50%;
  padding: 0.2rem;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}
.setResultWin div {
  padding: 0.1rem;
}
.header {
  background-color: #ffffff;
  border-radius: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem 0 0.15rem;
}
.title {
  color: #333;
  font-size: 0.28rem;
  font-family: PingFang SC;
}
.header_right {
  width: 50%;
  font-family: PingFang SC;
  color: #333;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
}
.header_right .right1 {
  width: 40%;
  text-align: right;
}
.header_right .right2 {
  width: 65%;
  text-align: center;
}
.menu_head {
  position: relative;
  z-index: 99;
  background-color: #ffffff;
}
.menu_head .menu_list {
  padding: 0.39rem 0.6rem 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu_head .menu_list .menu_item0 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33.3%;
}
.menu_head .menu_list .menu_item0 .txt {
  color: #6f7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item0 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item0 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .menu_list .menu_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
}
.menu_head .menu_list .menu_item1 .txt {
  color: #6f7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item1 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item1 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .menu_list .menu_item2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33.3%;
}
.menu_head .menu_list .menu_item2 .txt {
  color: #6f7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item2 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item2 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .xiala_msg {
  position: absolute;
  left: 0;
  top: 1rem;
  z-index: 99;
  background-color: #ffffff;
  width: 100%;
}
.menu_head .xiala_msg .xiala_list {
  padding: 0.2rem 0.24rem 0.2rem 0.24rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.menu_head .xiala_msg .xiala_list .xiala_item {
  width: 2.18rem;
  height: 0.72rem;
  background-color: #f7f7fa;
  font-size: 0.28rem;
  color: #6f7989;
  text-align: center;
  line-height: 0.72rem;
  margin-bottom: 0.2rem;
  border-radius: 0.08rem;
  margin-right: 0.24rem;
}
.menu_head .xiala_msg .xiala_list .xiala_item:nth-child(3) {
  margin-right: 0;
}
.menu_head .xiala_msg .xiala_list .xiala_item:nth-child(6) {
  margin-right: 0;
}
.menu_head .xiala_msg .xiala_list .xiala_item.on {
  background-color: #e7ecef;
  color: #333333;
  font-weight: bold;
}
.menu_head .menu_zhuangtai {
  padding: 0 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.menu_head .menu_zhuangtai .tai_item {
  background-color: #f7f7fa;
  color: #8f9eab;
  font-size: 0.24rem;
  padding: 0.06rem 0.24rem;
}
.menu_head .menu_zhuangtai .tai_item.on {
  font-weight: bold;
  color: #333333;
}
.quanxuan {
  color: #3893fb;
}
