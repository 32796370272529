.moreImg {
  width: 0.6rem;
  /* height: 0.2rem; */
}
.sspanStyleCol22 {
  margin-left: 0.2rem;
}
.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  margin: 0 0.3rem;
  padding: 0.15rem 0 0.2rem;
}
.vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  margin-top: 0.25rem;
}
.vanCol4PStyle span {
  font-family: Bahnschrift;
}
.bottomDivRowColStylePS {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.08rem;
  display: flex;
}
.bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.fontColorStyle {
  color: #333;
}
.zhongjiandeDiv {
  height: 0.8rem;
  font-size: 0.32rem;
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spanFlex6 {
  color: white;
  font-size: 0.32rem;
  text-align: center;
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.spanFlex6.sbtn1 {
  background-image: url('../../assets/imgs/wallet/icon_chargemoney.png');
}
.spanFlex6.sbtn2 {
  background-image: url('../../assets/imgs/wallet/icon_withdrawmoney.png');
}
.spanFlex6.sbtn3 {
  background-image: url('../../assets/imgs/wallet/icon_transferaccounts.png');
}
.body1Style {
  padding: 0 0.3rem 0.5rem;
}
.bgMask {
  padding-top: 0.45rem;
  background: url(../../assets/imgs/wallet/mask.png) no-repeat center top;
  background-size: 100% 0.45rem;
  margin-top: -0.45rem;
}
.whole {
  /* background: #ffffff; */
}
.accountInfo {
  color: white;
  font-size: 0.24rem;
  padding: 0.2rem 0.4rem;
  height: 1.36rem;
  background: linear-gradient(180deg, #989EFF 0%, #629CFB 100%);
  border-radius: 0.12rem;
  margin: 0 0.3rem;
}
.accountInfo .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accountInfo .yen {
  font-size: 0.44rem;
  font-family: Bahnschrift;
}
.leixin {
  width: 2.3rem;
}
