





























































































































































































.van-dropdown-item__option {
	margin-top: 5rem;
}
.van-cell--clickable {
	margin-top: 7rem;
}
.btnStyle {
	text-align: center;
	padding: 0.1rem;
	/* width: 1rem; */
	/* padding:1rem 1rem 1rem 1rem; */
	color: #ffffff;
	/* padding: 0px; */
	/* height: 0.5rem; */
	/*background: #ecbb34;*/
	border-radius: 5px;
}
.inputStyle {
	border: 0px;
	width: 70%;
}

.copyStyle {
	color: #ecbb34;
	margin: 0rem 0rem 0rem 2.7rem;
}

.imgPStyle {
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	font-size: 0.4rem;
	border: 1px solid #14aa41;
	width: 3rem;
	color: #14aa41;
	border-radius: 5px;
}

.imgDiv {
	text-align: center;
	padding: 0.7rem 1.77rem 0rem;
}

.imgStyle {
	margin: 1rem 0 0.6rem 0;
	width: 2.8rem;
}
.bth_1 {
	margin-top: 1rem;
	color: #ff4b04;
	padding: 3px;
	border: 2px solid #ff4b04;
	border-radius: 5px;
}

.daiBi {
	margin-top: 0.7rem;
}
.ulList {
	padding: 0.1rem 0.3rem;
	.list-cell {
		height: 0.88rem;
		line-height: 0.88rem;
		padding: 0 0.25rem;
		background: #ffffff;
		border-radius: 0.12rem;
		margin-bottom: 0.1rem;
		position: relative;
		.right {
			position: absolute;
			z-index: 1;
			right: 0.25rem;
			bottom: 0;
		}
	}
}
.fdStyle {
	background: #f1f1f1;
	height: 100%;
	/* padding: 0.3rem; */
}
.van-icon-arrow-left {
	color: #ffffff;
}

.van-hairline--bottom {
	background: #ecbb34;
	color: #ffffff;
}

.van-nav-bar__title {
	background: #ecbb34;
	color: #ffffff;
}

.whole {
	/* background: #ffffff; */
}
.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

.layoutBox {
	// min-height: calc(100vh - 1.76rem);
	min-height: calc(100vh - .88rem);
}
.chainInfo {
	padding: 0.25rem;
	background: #ffffff;
	border-radius: 0.16rem;
	margin-bottom: 1rem;
	.hd {
		color: #666666;
		font-size: 0.28rem;
		line-height: 0.44rem;
		padding-bottom: 0.25rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.2rem;
	}
	.bd {
		font-size: 0.24rem;
		line-height: 0.4rem;
		text-align: center;
	}
	.code {
		width: 256px;
		height: 256px;
		margin: 0 auto 0.25rem;
	}
	.codeTit {
		color: #666666;
		margin-bottom: 0.1rem;
	}
	.address {
		margin-bottom: 0.25rem;
	}
	.copy {
		color: #90c4ff;
		display: inline-block;
		padding: 0 0.2rem;
		border: 1px solid #90c4ff;
		border-radius: 0.04rem;
	}
}
.chainTips {
	color: #999999;
	font-size: 0.24rem;
	line-height: 0.35rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;