.log-list-item {
  color: #666666;
  font-size: 0.28rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.25rem;
}
.log-list-item .hd {
  padding: 0.25rem 0.2rem;
  border-bottom: 1px solid #F5F5F5;
}
.log-list-item .hd .caption {
  height: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}
.log-list-item .hd .caption .yen {
  color: #373737;
  margin-right: 0.25rem;
}
.log-list-item .hd .caption .type {
  font-size: 0.24rem;
}
.log-list-item .hd .specialFont {
  color: #7675D8 !important;
  text-transform: uppercase;
}
.log-list-item .hd .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.4rem;
}
.log-list-item .hd .info .date {
  color: #373737;
  font-size: 0.24rem;
}
.log-list-item .bd {
  padding: 0.2rem 0.2rem 0.3rem;
  display: flex;
  align-items: flex-start;
}
.log-list-item .bd span {
  width: 1.4rem;
  display: block;
}
.log-list-item .bd .remarks {
  flex: 1;
  color: #373737;
}
.log-list-item .fd {
  height: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.log-list-item .fd::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid #D8D8D8;
}
.log-list-item .fd img {
  width: 0.24rem;
  height: 0.24rem;
  display: block;
  background: #F5F5F5;
  position: relative;
  z-index: 2;
}
.log-list-item .fd img.up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
