
































































































































































































































































































































.moreImg {
  width: 0.6rem;
  /* height: 0.2rem; */
  // margin-left: 5rem;
}

.sspanStyleCol22 {
  margin-left: 0.2rem;
}

.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  margin: 0 0.3rem;
  padding: 0.15rem 0 .2rem;
}

.bottomDivRowColStyle {
  // padding: 0.1rem;
}

.vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  // color: #CF4E65;
  margin-top: 0.25rem;
  span {
	  font-family: Bahnschrift;
  }
}

.bottomDivRowColStylePS {
  // padding-bottom: 0.1rem;
  font-size: .28rem;
  line-height: .4rem;
  margin-top: .08rem;
  display: flex;
}

.bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: .35rem;
}

.fontColorStyle {
  // color: #999;
  color: #333;
}

.zhongjiandeDiv {
  height: 0.8rem;
  font-size: 0.32rem;
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spanFlex6 {
	color: white;
	font-size: 0.32rem;
  text-align: center;
  width: 100%;
  height: .88rem;
  line-height: .88rem;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  &.sbtn1 {
	  background-image: url('../../assets/imgs/wallet/icon_chargemoney.png');
  }
  &.sbtn2 {
  	  background-image: url('../../assets/imgs/wallet/icon_withdrawmoney.png');
  }
  &.sbtn3 {
  	  background-image: url('../../assets/imgs/wallet/icon_transferaccounts.png');
  }
}

.body1Style {
  padding: 0 0.3rem .5rem;
}
.bgMask {
	padding-top: .45rem;
	background: url(../../assets/imgs/wallet/mask.png) no-repeat center top;
	background-size: 100% .45rem;
	margin-top: -.45rem;
}

.whole {
  /* background: #ffffff; */
}

.accountInfo {
	color: white;
	font-size: .24rem;
	padding: .2rem .4rem;
	height: 1.36rem;
	background: linear-gradient(180deg, #989EFF 0%, #629CFB 100%);
	border-radius: .12rem;
	margin: 0 .3rem;
	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.yen {
		font-size: .44rem;
		font-family: Bahnschrift;
	}
}
.leixin {
	width: 2.3rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;