























































.h4Style {
	padding-bottom: 0.2rem;
}
.pStyle {
	word-wrap: break-word;
	word-break: break-all;
}
/deep/.pStyle img {
	max-width: 100% !important;
	margin:auto;
}
// .titleGuanLiStyle {
// 	font-size: 0.4rem;
// 	padding-top: 1.5rem;
// }
// .titlePStyle {
// 	margin-bottom: 0.2rem;
// 	margin-top: 0.3rem;
// 	padding-top: 0.1rem;
// 	transform: rotate(180deg);
// }
// .title {
// 	background: #f6f8f9;
// 	height: 2rem;
// 	padding: 0.3rem;
// }

// .tpuschool {
// 	background: #ffffff;
// 	height: 100%;
// }
// .details {
// 	height: 90%;
// 	// margin: 0.1rem 0.3rem 0rem 0.3rem;
// 	background: #ffffff;
// 	border-radius: 5px;
// 	padding: 0.45rem;
// }

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;