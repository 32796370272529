
















































































.list-icon-arrR {
	padding: .2rem 0;
	height: .88rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	.icon {
		width: .48rem;
		height: .48rem;
		margin-right: .12rem;
		img {
			width: 100%;
			display: block;
		}
	}
	.label {
		color: #666666;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;