.aboutOurs .contact {
  padding: 0.3rem 0;
}
.aboutOurs .contact .ico {
  padding: 1rem 0;
  font-size: 0.24rem;
  text-align: center;
}
.aboutOurs .contact .ico img {
  display: block;
  width: 100px;
  margin: auto;
}
.aboutOurs .contact .contact-item {
  height: 0.88rem;
  border-bottom: 1px solid #EDEDED;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aboutOurs .contact .contact-item .icon {
  width: 0.48rem;
  display: block;
}
.aboutOurs .contact .contact-item .label {
  color: #171717;
  font-weight: bold;
}
.layoutBox {
  height: calc(100vh - 1.76rem);
  padding: 0.25rem 0.3rem;
}
