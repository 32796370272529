.bill-list-item {
  z-index: -99 !important;
  color: #666666;
  font-size: 0.28rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.25rem;
}
.bill-list-item .hd {
  padding: 0.25rem 0.2rem;
}
.bill-list-item .hd .caption {
  height: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}
.bill-list-item .hd .caption .yen {
  color: #373737;
  font-size: 0.32rem;
  margin-right: 0.06rem;
}
.bill-list-item .hd .caption .yen1 {
  color: #999999;
  margin-right: 0.25rem;
  font-size: 0.24rem;
}
.bill-list-item .hd .caption .type {
  font-size: 0.24rem;
}
.bill-list-item .hd .specialFont {
  color: #7675D8 !important;
  text-transform: uppercase;
}
.bill-list-item .hd .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.4rem;
}
.bill-list-item .hd .info .date {
  color: #373737;
  font-size: 0.24rem;
}
.bill-list-item .bd {
  color: #373737;
  padding: 0.3rem 0.2rem;
  border-top: 1px solid #F5F5F5;
}
.bill-list-item .bd .msg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.24rem;
  text-align: center;
}
.bill-list-item .bd .msg2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.bill-list-item .bd .item1 {
  width: 30%;
}
.bill-list-item .bd .item1 .vlu {
  color: #373737;
  font-size: 0.28rem;
  margin-bottom: 0.02rem;
}
.bill-list-item .bd .item1 .name {
  color: #999999;
  font-size: 0.24rem;
}
.bill-list-item .bd .item2 {
  width: 30%;
}
.bill-list-item .bd .item2 .vlu {
  color: #373737;
  font-size: 0.28rem;
  margin-bottom: 0.02rem;
}
.bill-list-item .bd .item2 .name {
  color: #999999;
  font-size: 0.24rem;
}
.bill-list-item .bd .item3 {
  width: 30%;
}
.bill-list-item .bd .item3 .vlu {
  color: #373737;
  font-size: 0.28rem;
  margin-bottom: 0.02rem;
  text-align: center;
}
.bill-list-item .bd .item3 .name {
  color: #999999;
  font-size: 0.24rem;
}
.bill-list-item .fd {
  height: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bill-list-item .fd::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 1px solid #F5F5F5;
}
.bill-list-item .fd img {
  width: 0.24rem;
  height: 0.24rem;
  display: block;
  background: #FFFFFF;
  position: relative;
  z-index: 2;
}
.bill-list-item .fd img.up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
