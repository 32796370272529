.comlist {
  padding: 0.4rem 0;
  line-height: 0.45rem;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: space-between;
}
.comlist .icon {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.12rem;
}
.comlist .icon img {
  width: 100%;
  display: block;
}
.comlist .label {
  color: #373737;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
}
.comlist .icon_arrR {
  margin-right: 0;
}
