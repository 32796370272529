.header {
  margin-bottom: 0.2rem;
  border-radius: 0.15rem;
  height: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem 0 0.15rem;
}
.title {
  color: #373737;
  font-size: 0.32rem;
  font-family: PingFang SC;
  width: 50%;
}
.header_right {
  width: 50%;
  font-family: PingFang SC;
  color: #373737;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
}
.header_right .right1 {
  width: 40%;
  text-align: right;
}
.header_right .right2 {
  width: 65%;
  text-align: center;
}
.quantification .listQuan {
  padding-bottom: 0.1rem;
  background-color: #ffffff;
  border-radius: 0.18rem;
  margin-bottom: 0.2rem;
}
.quantification .listQuan:last-child {
  border-bottom: none;
}
.listQuan_1 {
  display: flex;
  justify-content: space-between;
  margin: 0 0.25rem 0.1rem 0.15rem;
  padding-top: 16px;
  align-items: center;
}
.listQuan_1 .listQuan_1_left {
  display: flex;
  align-items: center;
  width: 60%;
}
.listQuan_1 .listQuan_1_left .csTitle span {
  color: #90C4FF;
  font-size: 0.2rem;
  padding: 0 0.1rem;
  line-height: 0.36rem;
  background: #F1F7FF;
  border-radius: 0.04rem;
  display: inline-block;
  margin-left: 0.1rem;
}
.listQuan_1 .listQuan_1_left .csTitle span.gray {
  color: #9B9B9B;
  background: #F5F5F5;
}
.listQuan_1 .listQuan_1_left .csTitle span.gray1 {
  color: #DE5841;
  background: rgba(222, 88, 65, 0.09);
}
.listQuan_1 .listQuan_1_left .csTitle span.gray2 {
  color: #4DAA90;
  background: rgba(77, 170, 144, 0.09);
}
.listQuan_1 .listQuan_1_left .checkbox {
  margin-left: 0.15rem;
  display: flex;
  align-items: center;
}
.listQuan_1 .listQuan_1_left .content_top {
  margin-left: 0.25rem;
  display: flex;
  align-items: flex-end;
}
.listQuan_1 .listQuan_1_left .content_top .name {
  font-size: 0.34rem;
  color: #181818;
  padding-bottom: 0.04rem;
}
.listQuan_1 .listQuan_1_left .content_top .name1 {
  font-size: 0.24rem;
  color: #999;
  padding-bottom: 0.09rem;
}
.listQuan_1 .listQuan_1_left .content_top .name2 {
  font-size: 0.41rem;
  color: #999;
}
.listQuan_1 .listQuan_1_right {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}
.listQuan_1 .listQuan_1_right .right1 {
  text-align: center;
  border-radius: 4px;
  font-size: 0.24rem;
  padding: 5px;
  color: #fff;
}
.listQuan_1 .listQuan_1_right .green {
  border: 1px solid #07ad91;
  background-color: #07ad91;
}
.listQuan_1 .listQuan_1_right .red {
  background-color: #cf4e65;
  border: 1px solid #cf4e65;
}
.listQuan_1 .listQuan_1_right .right2 {
  width: 50%;
  font-weight: bold;
  text-align: center;
}
.listQuan_2 {
  margin: 0.1rem 0.25rem 0.1rem 0.15rem;
  padding: 12px 0 16px 0;
  border-bottom: 0.1px solid #ededed;
  justify-content: space-around;
  display: flex;
}
.listQuan_2 .listQuan_2_item {
  text-align: center;
}
.listQuan_2 .listQuan_2_item .label {
  font-size: 12px;
  color: #666666;
}
.listQuan_2 .listQuan_2_item .value {
  color: #373737;
  font-size: 14px;
}
.listQuan_2 .listQuan_2_item .green {
  color: #07ad91;
}
.listQuan_2 .listQuan_2_item .red {
  color: #cf4e65;
}
.listQuan_2 .listQuan_2_name {
  color: #373737;
  font-size: 0.36rem;
  font-family: PingFang SC;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  align-items: center;
}
.listQuan_2 .listQuan_2_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.8rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left {
  display: flex;
  align-items: center;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left .chicang {
  font-size: 0.36rem;
  font-weight: 550;
  margin-right: 0.1rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left .bucang {
  font-size: 0.22rem;
  color: #373737;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right {
  display: flex;
  align-items: center;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .fiyin {
  font-size: 0.36rem;
  font-weight: 550;
  margin-left: 0.1rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .fudu {
  font-size: 0.24rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .green {
  color: #07ad91;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .red {
  color: #cf4e65;
}
.listQuan_3 {
  height: 1rem;
  padding: 0.1rem 0.25rem 0.1rem 0.15rem;
  display: flex;
  align-items: center;
}
.listQuan_3 .btn {
  text-align: center;
  flex: 1;
  height: 26px;
  padding-top: 2px;
  font-size: 16px;
  color: #3893FB;
  font-weight: bold;
}
.listQuan_3 .red {
  color: #CF4E65 !important;
}
