















































.log-list-item {
	color: #666666;
	font-size: .28rem;
	// background: #F5F5F5;
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: 0.25rem;
	.hd {
		padding: .25rem .2rem;
		border-bottom: 1px solid #F5F5F5;
		.caption {
			height: .4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .25rem;
			.yen {
				color: #373737;
				margin-right: .25rem;
			}
			.type {
				font-size: .24rem;
			}
		}
		.specialFont {
			color: #7675D8 !important;
			text-transform: uppercase;
		}
		.info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: .4rem;
			.date {
				color: #373737;
				font-size: .24rem;
			}
		}
	}
	.bd {
		// padding: .2rem .2rem  0;
		padding: .2rem .2rem .3rem;
		display: flex;
		align-items: flex-start;
		span {
			width: 1.4rem;
			display: block;
		}
		.remarks {
			flex: 1;
			color: #373737;
		}
	}
	.fd {
		height: .5rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			top: 50%;
			border-top: 1px solid #D8D8D8;
		}
		img {
			width: .24rem;
			height: .24rem;
			display: block;
			background: #F5F5F5;
			position: relative;
			z-index: 2;
			&.up {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
	
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;