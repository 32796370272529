.btn1,
.btn2 {
  color: #000;
  border: 0;
  font-size: 14px;
  background: none;
  padding: 0;
  margin-right: 0.36rem;
}
/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/
.commonHead1 {
  background-color: #ffffff;
}
.commonHead1 .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
  background-color: #ffffff !important;
  padding: 0 0.3rem;
}
.commonHead1 .left {
  position: absolute;
  left: 0.3rem;
  top: 0;
}
.commonHead1 .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHead1 .left .icon .icon_arrL {
  border-top: 0.04rem solid #000;
  border-left: 0.04rem solid #000;
}
.commonHead1 .left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHead1 .title {
  color: #333333;
  font-size: 0.32rem;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}
.commonHead1 .right {
  position: absolute;
  right: 0.3rem;
  top: 0;
  height: 0.88rem;
  display: flex;
  align-items: center;
}
.commonHead1 .right .text {
  color: #6f7a8b;
  font-size: 0.28rem;
}
.commonHead1 .right img {
  width: 0.4rem;
  height: 0.4rem;
}
.titlePStyle {
  /* margin-bottom: 0.2rem; */
  margin-top: 0.2rem;
  /* padding-top: 0.1rem; */
  margin-right: 7rem;
  transform: rotate(180deg);
}
.qrcode {
  width: 0.3rem;
}
.fengXiangCode {
  position: absolute;
  bottom: 6.5rem;
  left: 3.2rem;
}
.spanStyle {
  margin-right: 7rem;
}
.head_img1 {
  position: absolute;
  top: 0.85rem;
  left: 1.95rem;
  width: 3.66rem;
  height: 1.53rem;
}
.head_img1 img {
  width: 100%;
  height: 100%;
}
.head_img2 {
  position: absolute;
  top: 2.87rem;
  left: 2.06rem;
  width: 3.39rem;
  height: 0.5rem;
  line-height: 0.5rem;
  background-color: #FFFFFF;
  border-radius: 0.25rem;
}
.head_img2 .img {
  margin: 0 auto;
  width: 2.92rem;
  height: 0.27rem;
}
.head_img2 .img img {
  width: 100%;
  height: 100%;
}
.mid_content {
  position: absolute;
  left: 0.7rem;
  right: 0.7rem;
  top: 8.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mid_content .mid_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1.44rem;
}
.mid_content .mid_item .img {
  width: 0.44rem;
  height: 0.44rem;
  margin-bottom: 0.26rem;
}
.mid_content .mid_item .img img {
  height: 100%;
  width: 100%;
}
.mid_content .mid_item .mid_txt {
  text-align: center;
  font-size: 0.24rem;
  color: #FFFFFF;
}
.mid_content .mid_item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2.16rem;
}
.mid_content .mid_item1 .img {
  margin-bottom: 0.26rem;
  width: 0.44rem;
  height: 0.44rem;
}
.mid_content .mid_item1 .img img {
  height: 100%;
  width: 100%;
}
.mid_content .mid_item1 .mid_txt {
  text-align: center;
  font-size: 0.24rem;
  color: #FFFFFF;
}
.op_container {
  flex: 1;
  text-align: left;
}
.container {
  background-position: center top;
  background-size: 100% 100%;
  font-family: PingFangSC-Regular;
}
.container .content {
  height: calc(100vh - 44px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.container .shareFooter {
  position: absolute;
  bottom: 0.89rem;
  left: 0;
  right: 0;
  margin: 0 0.4rem;
  display: flex;
  align-items: center;
}
.container .shareFooter .code {
  width: 1.29rem;
  height: 1.29rem;
  margin: 0 auto;
}
.container .shareFooter .codeImgStyle {
  width: 1.42rem;
  height: 1.42rem;
  border: 3px solid #FFFFFF;
}
.container .shareFooter .codeImgStyle img {
  width: 100%;
  height: 100%;
}
.container .shareFooter .yaoQingStyle {
  color: #FFFFFF;
  font-size: 0.32rem;
}
.container .shareFooter .yaoQingStyle span {
  font-family: PingFangSC-Medium;
  font-weight: bold;
}
.container .shareFooter .yaoQingStyle:last-child {
  margin-top: 0.52rem;
}
.container .shareFooter .rightBtn {
  margin-right: 10px;
}
.footerBtn {
  color: #ffffff;
  font-size: 0.58rem;
  width: 4.24rem;
  height: 1.54rem;
  padding-bottom: 0.14rem;
  border-radius: 0.77rem;
  background: #ff4b04 url(../../assets/imgs/share/VIP.png);
  background-size: 100% 100%;
  margin: 0 auto;
}
.footerBtn span {
  font-weight: bold;
}
.layoutBox {
  padding: 0.1rem 0.3rem 0.5rem;
}
.layoutBox .shareAds {
  margin-bottom: 0.1rem;
}
.layoutBox .shareAds img {
  display: block;
  width: 100%;
}
.layoutBox .shareSteps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
  background: #FFFFFF;
  margin-bottom: 0.1rem;
}
.layoutBox .shareSteps .item {
  font-size: 0.28rem;
  text-align: center;
}
.layoutBox .shareSteps .item .icon {
  margin-bottom: 0.1rem;
}
.layoutBox .shareSteps .item .icon img {
  width: 0.64rem;
  height: 0.64rem;
  display: block;
  margin: 0 auto;
}
.layoutBox .shareSteps .item p {
  width: 4em;
}
.layoutBox .shareSteps .item p.mtext {
  width: 6em;
}
.layoutBox .shareSteps .split {
  width: 0.32rem;
  height: 0.3rem;
  background: url(../../assets/imgs/share/arr.png) no-repeat center center;
  background-size: 100% 100%;
}
.layoutBox .shareInfo {
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.1rem;
}
.layoutBox .shareInfo .top {
  padding: 0.25rem;
  border-bottom: 1px solid #EDEDED;
}
.layoutBox .shareInfo .bottom {
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
}
.layoutBox .shareInfo .bottom .btn {
  color: white;
  font-size: 0.32rem;
  text-align: center;
  width: 3.25rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #93C4FC;
  border-radius: 0.12rem;
}
.layoutBox .shareInfo .bottom .btn.btnR {
  background: #E0C3FC;
  position: relative;
}
.layoutBox .shareInfo .bottom .btn.btnR::before {
  content: '';
  position: absolute;
  left: -0.15rem;
  width: 0.3rem;
  height: 100%;
  background: #FFFFFF;
  transform: skewX(-15deg);
  transform-origin: 50% 50%;
}
.layoutBox .shareInfo .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}
.layoutBox .shareInfo .item:last-child {
  margin-bottom: 0;
}
.layoutBox .shareInfo .item .inp {
  height: 0.7rem;
  line-height: 0.7rem;
  padding: 0 0.1rem 0 0.35rem;
  background: #F6F7F9;
  border-radius: 0.34rem;
  display: flex;
  justify-content: space-between;
}
.layoutBox .shareInfo .item .inp .link {
  width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layoutBox .shareInfo .item .copy {
  width: 0.7rem;
  height: 0.7rem;
  background: url(../../assets/imgs/share/invitation_icon_file.png) no-repeat center center;
  background-size: 0.4rem 0.4rem;
  border: 0;
}
.layoutBox .shareReward {
  padding: 0.25rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
}
.layoutBox .shareReward .caption {
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.15rem;
}
.layoutBox .shareReward .caption h3 {
  font-size: 0.32rem;
}
.posterLayout {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 4, 15, 0.4);
}
.posterCode {
  position: fixed;
  z-index: 99;
  left: 0.3rem;
  right: 0.3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.posterCode .poster {
  position: relative;
}
.posterCode .poster img {
  display: block;
  width: 100%;
}
.posterCode .poster .code {
  position: absolute;
  left: 50%;
  top: 2.9rem;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: -0.9rem;
}
.posterCode .close {
  width: 0.8rem;
  height: 0.8rem;
  background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
  background-size: 0.6rem 0.6rem;
  margin: 0.5rem auto 0;
}
