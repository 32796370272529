

































































































.bill-list-item {
	z-index: -99 !important;
	color: #666666;
	font-size: .28rem;
	// background: #F5F5F5;
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: 0.25rem;
	.hd {
		padding: .25rem .2rem;
		.caption {
			height: .4rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: .25rem;
			.yen {
				color: #373737;
				font-size: 0.32rem;
				margin-right: 0.06rem;
			}
			.yen1 {
				color: #999999;
				margin-right: .25rem;
				font-size: 0.24rem;
			}
			.type {
				font-size: .24rem;
			}
		}
		.specialFont {
			color: #7675D8 !important;
			text-transform: uppercase;
		}
		.info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: .4rem;
			.date {
				color: #373737;
				font-size: .24rem;
			}
		}
	}
	.bd {
		color: #373737;
		// padding: .5rem .2rem  0;
		padding: .3rem .2rem;
		border-top: 1px solid #F5F5F5;
		.msg {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.24rem;
			text-align: center;
		}
		.msg2 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: center;
		}
		.item1{
			width: 30%;
			.vlu {
				color: #373737;
				font-size: 0.28rem;
				margin-bottom: 0.02rem;
			}
			.name {
				color: #999999;
				font-size: 0.24rem;
			}
		}
		.item2{
			width: 30%;
			.vlu {
				color: #373737;
				font-size: 0.28rem;
				margin-bottom: 0.02rem;
			}
			.name {
				color: #999999;
				font-size: 0.24rem;
			}
		}
		.item3{
			width: 30%;
			.vlu {
				color: #373737;
				font-size: 0.28rem;
				margin-bottom: 0.02rem;
				text-align: center;
			}
			.name {
				color: #999999;
				font-size: 0.24rem;
			}
		}
	}
	.fd {
		height: .5rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			top: 50%;
			border-top: 1px solid #F5F5F5;
		}
		img {
			width: .24rem;
			height: .24rem;
			display: block;
			// background: #F5F5F5;
			background: #FFFFFF;
			position: relative;
			z-index: 2;
			&.up {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
	
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;