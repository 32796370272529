



































































































































































.whole {
  // background: #ffffff;
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.titleList {
  font-weight: bold;
  margin: 0 0.3rem;
}
.billNum {
  text-align: center;
  padding: 0.4rem 0rem 0.6rem 0rem;

  p {
    font-size: 0.27rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  span {
    display: block;
    margin-top: 0.2rem;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #fff;
  }
}

.cumulative {
  background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
  background-size: 100% 100%;
}

.today {
  background: url(../../assets/imgs/bill/jryl.png) no-repeat;
  background-size: 100% 100%;
}

.bodySpanStyle {
  color: #333;
  font-size: 0.32rem;
  line-height: .45rem;
  margin-bottom: .06rem;
  &.num {
    color: #42854a;
	font-size: .4rem;
	font-family: Bahnschrift;
  }
}

.bodyLiStyle {
  // margin: 0.2rem 0;
  color: #666666;
  font-size: 0.24rem;
  line-height: .35rem;
  &.right {
	  width: 2.4rem;
  }
}

.bodyDivStyle {
  // border-radius: 5px;
  // background: #ffffff;
  margin: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: .25rem 0;
  // padding-bottom: 0.1rem;
  border-bottom: 1px solid #EDEDED;
}
.bodyDivStyle:last-child {
  border-bottom: none;
}

.titleVanRowDivStyle {
  width: 100%;
  background: #ffffff;
}


// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	background: #FFFFFF;
	box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			color: #666666;
			font-size: .28rem;
			line-height: .4rem;
			width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #FF9872;
				font-size: .52rem;
				font-family: Bahnschrift;
				line-height: .6rem;
				margin-bottom: .1rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	// font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	margin-bottom: .25rem;
}
.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;