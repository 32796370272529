




















































































































































































































































































































































































































































































































































































































































































































.quantification {
	padding-bottom: 50px;
	background-color: #f5f5f5;
	.van-checkbox__icon--disabled {
		.checkboxIco {
			background: #ddd;
		}
	}
	.checkboxIco {
		width: 0.33rem;
		height: 0.33rem;
		border: 1px solid #8e8f91;
		display: inline-block;
		border-radius: 100px;
	}
	.checkboxIco.on {
		background: #b6d7f8;
		border: 1px solid #b6d7f8;
		position: relative;
	}
	.checkboxIco.on:before,
	.checkboxIco.on::after {
		content: '';
		height: 9px;
		width: 1.5px;
		border-radius: 10px;
		display: block;
		background: #ffffff;
		position: absolute;
		top: 3.5px;
		left: 8.5px;
		transform: rotate(45deg);
		-ms-transform: rotate(45deg);
	}
	.checkboxIco.on::before {
		height: 5px;
		transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		position: absolute;
		top: 6px;
		left: 3.5px;
	}
	.setup {
		padding: 0 0.3rem;
		width: 100%;
		overflow: hidden;
		height: 0.54rem;
		line-height: 0.54rem;
		background: #f3f8ff;
		position: fixed;
		left: 0;
		bottom: 0;
		font-size: 0.24rem;
		font-weight: 500;
		color: #3893fb;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.van-search {
		padding: 0;
	}

	.van-search__content {
		background: #fff;
		// border: 1px solid #fc9216;
		// border-radius: 100px;
		// height: .6rem;
		height: 0.8rem;
		border-radius: 0.5rem;
		// border: 1px solid #90c4ff;
		padding: 2px 0.2rem;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;

		.van-field__control {
			font-size: 0.28rem;
			height: 0.52rem;
		}
		.van-field__control::-webkit-input-placeholder {
			color: rgb(152, 152, 152);
		}
	}

	.listButton {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		.buttonSetup {
			border: 1px solid rgb(158, 158, 158);
			color: rgb(158, 158, 158);
			border-radius: 4px;
			background: #fff;
			font-size: 0.2rem;
			width: 0.8rem;
			height: 0.38rem;
			margin-bottom: 0.1rem;
		}
		.buttonStart {
			border-radius: 4px;
			background: #fff;
			font-size: 0.2rem;
			width: 0.8rem;
			height: 0.38rem;
		}
		.buttonStart,
		.buttonNone,
		.buttonStop {
			border: 1px solid rgb(252, 151, 40);
			color: rgb(252, 151, 40);
			border-radius: 4px;
			background: #fff;
			font-size: 0.2rem;
			width: 0.8rem;
			height: 0.38rem;
		}
		.buttonStop {
			border: 1px solid rgb(217, 105, 105);
			color: rgb(217, 105, 105);
		}

		.van-button--small {
			width: 0.78rem;
			height: 0.42rem;
			line-height: 0.4rem;
			text-align: center;
			margin-right: 0.27rem;
			border: none;
			padding: 0;
			min-width: 0.77rem;
			border-radius: 5px;

			.van-button__text {
				color: #fff;
				font-size: 0.23rem;
				font-family: PingFang SC;
				font-weight: 500;
			}
		}

		.van-button--small:last-child {
			margin-right: 0;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;