.direcTionPStyle2 {
  font-size: 0.28rem;
  padding-top: 0.2rem;
  padding-bottom: 0.07rem;
  color: #333333;
}
.direcTionPStyle1 {
  font-size: 0.26rem;
  color: #333333;
}
.direcTionPStyle {
  margin-top: 0.2rem;
  font-size: 0.26rem;
  color: #999999;
}
.ulStyle {
  height: 100%;
}
.ulLiStyle {
  padding: 0.25rem 0;
  border-bottom: 1px solid #ededed;
}
.ulLiStyle .caption {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
  line-height: 0.44rem;
  height: 0.88rem;
  overflow: hidden;
}
.ulLiStyle .date {
  color: #999999;
  font-size: 0.24rem;
  line-height: 0.44rem;
  margin-top: 0.1rem;
}
.ulLiStyle .picture {
  width: 2.25rem;
  height: 1.4rem;
  border-radius: 0.1rem;
}
.pStyle {
  margin-top: 0.2rem;
}
.vanStep p {
  color: #b1b1b1;
  font-size: 0.001rem;
}
.pStyle {
  font-size: 0.2rem;
  color: #404040;
}
.vanStep h3 {
  font-size: 0.2rem;
  margin: 0.1rem;
  color: #404040;
}
.journalStyle {
  background: #ffffff;
  border-radius: 5px;
  height: 100%;
}
.whole {
  background: #ffffff;
}
.title {
  background: #f6f8f9;
  height: 1rem;
  padding: 0.3rem;
}
.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}
.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
