






























































.commonHeader {
	position: relative;
	z-index: 100;
	// padding: .88rem .3rem 0;
	padding: 0 .3rem;
	margin-bottom: .3rem;
	background-image: url(../assets/imgs/common_bg.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 3.5rem;
	.head {
		position: relative;
		height: .88rem;
		line-height: .8rem;
	}
	.menuBtn {
		position: absolute;
		left: 0;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.title {
		color: #ffffff;
		font-size: .32rem;
		// font-weight: bold;
		text-align: center;
	}
	.langBtn {
		position: absolute;
		right: 0;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.tabTit {
		color: #FFFFFF;
		font-size: .36rem;
		display: flex;
		padding: 0 2rem;
		justify-content: space-around;
		.active {
			font-size: .4rem;
			font-weight: bold;
			border-bottom: 1px solid #FFFFFF;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;