.commonRouter {
  margin: 0.25rem 0.3rem;
  display: flex;
  justify-content: space-between;
}
.commonRouter li {
  color: #FFFFFF;
  font-size: 0.36rem;
  width: 3.34rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.35rem;
  height: 0.72rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
.commonRouter li.active {
  color: #90C4FF;
}
.commonRouter.active-l li {
  background-image: url(../../assets/imgs/icon/tab_r.png);
}
.commonRouter.active-l .active {
  background-image: url(../../assets/imgs/icon/tab_l-on.png);
}
.commonRouter.active-r li {
  background-image: url(../../assets/imgs/icon/tab_l.png);
}
.commonRouter.active-r .active {
  background-image: url(../../assets/imgs/icon/tab_r-on.png);
}
.layoutBox {
  padding: 0 0.3rem;
  min-height: calc(100vh - 2.1rem);
  position: relative;
}
.layoutBox .tan {
  z-index: 99;
  position: absolute;
  right: 0;
  top: 0.8rem;
  background-color: #FFFFFF;
  width: 100%;
  height: 6.4rem;
}
.layoutBox .tan .content {
  margin: 0.32rem 0.3rem;
}
.layoutBox .tan .content .head_1 {
  color: #373737;
  font-size: 0.32rem;
  margin-bottom: 0.16rem;
}
.layoutBox .tan .content .exe_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.32rem;
}
.layoutBox .tan .content .exe_list .item {
  padding: 0.1rem 0.18rem;
  background-color: #F5F5F5;
  color: #999999;
  text-align: center;
}
.layoutBox .tan .content .exe_list .active {
  background-color: #E7F1FF;
  color: #2568E4;
  position: relative;
}
.layoutBox .tan .content .exe_list .active:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0.12rem solid #1677FF;
  border-top-color: transparent;
  border-left-color: transparent;
}
.layoutBox .tan .content .exe_list .active:after {
  content: '';
  width: 1px;
  height: 3px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.layoutBox .tan .content .exe_list1 {
  display: flex;
  align-items: center;
  margin-bottom: 0.32rem;
}
.layoutBox .tan .content .exe_list1 .item {
  padding: 0.1rem 0.18rem;
  background-color: #F5F5F5;
  color: #999999;
  text-align: center;
  margin-left: 0.5rem;
}
.layoutBox .tan .content .exe_list1 .item:first-child {
  margin-left: 0;
}
.layoutBox .tan .content .exe_list1 .active {
  background-color: #E7F1FF;
  color: #2568E4;
  position: relative;
}
.layoutBox .tan .content .exe_list1 .active:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0.12rem solid #1677FF;
  border-top-color: transparent;
  border-left-color: transparent;
}
.layoutBox .tan .content .exe_list1 .active:after {
  content: '';
  width: 1px;
  height: 3px;
  position: absolute;
  right: 1px;
  bottom: 1px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.layoutBox .tan .content .head_2 {
  color: #373737;
  font-size: 0.32rem;
  margin-bottom: 0.38rem;
}
.layoutBox .tan .content .dui_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.48rem;
}
.layoutBox .tan .content .dui_list .input {
  border-bottom: 0.02rem solid #2568E4;
}
.layoutBox .tan .content .dui_list .input input {
  border: none;
  text-align: center;
  font-size: 0.24rem;
  color: #999999;
  padding: 0.12rem 0;
}
.layoutBox .tan .content .dui_list .fenge {
  color: #2568E4;
  font-size: 0.56rem;
}
.layoutBox .tan .content .button {
  display: flex;
  justify-content: space-between;
}
.layoutBox .tan .content .button .button_1 {
  color: #2568E4;
  font-size: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.35rem;
  height: 0.8rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url(../..//assets/imgs/bag-w.png);
}
.layoutBox .tan .content .button .button_2 {
  color: #FFFFFF;
  font-size: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.35rem;
  height: 0.8rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url(../..//assets/imgs/bag-b.png);
}
.commonTabtit {
  color: #373737;
  font-size: 0.24rem;
  height: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 0.2rem;
}
.commonTabtit .type {
  display: flex;
  align-items: center;
}
.commonTabtit .type li {
  line-height: 0.8rem;
  margin-right: 0.5rem;
}
.commonTabtit .type li.active {
  font-size: 0.28rem;
  font-weight: bold;
}
.commonTabtit .img img {
  width: 0.34rem;
  height: 0.34rem;
}
.commonTabtit .exchange {
  height: 0.8rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.commonTabtit .exchange .icon_arr_up {
  width: 0.1rem;
  height: 0.1rem;
  border-right: 2px solid #666666;
  border-bottom: 2px solid #666666;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 0.15rem;
  display: inline-block;
}
.van-list {
  z-index: -99 !important;
}
.zhezhao {
  top: 22vh;
}
