





















































































































.direcTionPStyle2 {
	font-size: 0.28rem;
	padding-top: 0.2rem;
	padding-bottom: 0.07rem;
	color: #333333;
}
.direcTionPStyle1 {
	font-size: 0.26rem;
	color: #333333;
}
.direcTionPStyle {
	margin-top: 0.2rem;
	font-size: 0.26rem;
	color: #999999;
}
.tabsVanStyle {
	// padding: 0.3rem;
	// height: 3rem;
}
.ulStyle {
	height: 100%;
}

.ulLiStyle {
	// margin-top: 0.3rem;
	// padding: 0.3rem;
	padding: 0.25rem 0;
	border-bottom: 1px solid #ededed;
}
.ulLiStyle .caption {
	color: #333333;
	font-size: 0.28rem;
	font-weight: bold;
	line-height: 0.44rem;
	height: 0.88rem;
	overflow: hidden;
}
.ulLiStyle .date {
	color: #999999;
	font-size: 0.24rem;
	line-height: 0.44rem;
	margin-top: 0.1rem;
}
.ulLiStyle .picture {
	width: 2.25rem;
	height: 1.4rem;
	border-radius: 0.1rem;
}

.pStyle {
	// margin-left: 0.4rem;
	margin-top: 0.2rem;
}

.vanStep p {
	color: #b1b1b1;
	font-size: 0.001rem;
}

.pStyle {
	font-size: 0.2rem;
	color: #404040;
}

.vanStep h3 {
	font-size: 0.2rem;
	margin: 0.1rem;
	color: #404040;
	//   padding-bottom: 0.4rem;
}

.journalStyle {
	// margin-left: 0.4rem;
	// margin-right: 0.4rem;
	background: #ffffff;
	// margin-top: 0.2rem;
	border-radius: 5px;
	height: 100%;
}

.whole {
	background: #ffffff;
}
.title {
	background: #f6f8f9;
	height: 1rem;
	padding: 0.3rem;
}
.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;