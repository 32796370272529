



































.comlist {
	padding: .4rem 0;
	line-height: .45rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	.icon {
		width: .4rem;
		height: .4rem;
		margin-right: .12rem;
		img {
			width: 100%;
			display: block;
		}
	}
	.label {
		color: #373737;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon_arrR{
		margin-right: 0;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;