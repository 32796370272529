.main {
  height: 32px;
  border-radius: 8px;
  border-color: #3893FB;
  border: 1px solid #3893FB;
  position: relative;
  padding-left: 12px;
  display: flex;
  align-items: center;
}
.main input {
  border: none;
  font-size: 12px;
  margin: 5px;
  flex: 1;
}
.main .btn {
  height: 32px;
  border-radius: 0 8px 8px 0;
  background-color: #3893FB;
  line-height: 32px;
  color: #fff;
  padding: 0 12px 0 17px;
}
