



































































































































































































































































































































































































































































































































































































































.commonRouter {
	margin: .25rem .3rem;
	display: flex;
	justify-content: space-between;
	li {
		color: #FFFFFF;
		font-size: .36rem;
		width: 3.34rem;
		height: .72rem;
		// border: 2px solid #FFFFFF;
		// border-radius: .36rem;
		display: flex;
		justify-content: center;
		align-items: center;
		
		width: 3.35rem;
		height: .72rem;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		&.active {
			color: #90C4FF;
			// background: #FFFFFF;
		}
	}
	&.active-l {
		li {
			background-image: url(../../assets/imgs/icon/tab_r.png);
		}
		.active {
			background-image: url(../../assets/imgs/icon/tab_l-on.png);
		}
	}
	&.active-r {
		li {
			background-image: url(../../assets/imgs/icon/tab_l.png);
		}
		.active {
			background-image: url(../../assets/imgs/icon/tab_r-on.png);
		}
	}
}

.layoutBox {
	padding: 0 .3rem;
	min-height: calc(100vh - 2.1rem);
	position: relative;
	.tan {
		z-index: 99;
		position: absolute;
		right: 0;
		top: 0.8rem;
		background-color: #FFFFFF;
		width: 100%;
		height: 6.4rem;
		.content {
			margin: 0.32rem 0.3rem;
			.head_1 {
				color: #373737;
				font-size: 0.32rem;
				margin-bottom: 0.16rem;
			}
			.exe_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.32rem;
				.item {
					padding: 0.1rem 0.18rem;
					background-color: #F5F5F5;
					color: #999999;
					text-align: center;
				}
				.active {
					background-color: #E7F1FF;
					color: #2568E4;
					position: relative;
				}
				.active:before {
				        content: '';
				        position: absolute;
				        right: 0;
				        bottom: 0;
				        border: 0.12rem solid #1677FF;
				        border-top-color: transparent;
				        border-left-color: transparent;
				}
				.active:after {
				    content: '';
					width: 1px;
					height: 3px;
					position: absolute;
					right: 1px;
					bottom: 1px;
					border: 2px solid #fff;
					border-top-color: transparent;
					border-left-color: transparent;
					transform: rotate(45deg);
				}
			}
			.exe_list1 {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				margin-bottom: 0.32rem;
				.item {
					padding: 0.1rem 0.18rem;
					background-color: #F5F5F5;
					color: #999999;
					text-align: center;
					margin-left: 0.5rem;
					&:first-child {
						margin-left: 0;
					}
				}
				.active {
					background-color: #E7F1FF;
					color: #2568E4;
					position: relative;
				}
				.active:before {
				        content: '';
				        position: absolute;
				        right: 0;
				        bottom: 0;
				        border: 0.12rem solid #1677FF;
				        border-top-color: transparent;
				        border-left-color: transparent;
				}
				.active:after {
				    content: '';
					width: 1px;
					height: 3px;
					position: absolute;
					right: 1px;
					bottom: 1px;
					border: 2px solid #fff;
					border-top-color: transparent;
					border-left-color: transparent;
					transform: rotate(45deg);
				}
			}
			.head_2 {
				color: #373737;
				font-size: 0.32rem;
				margin-bottom: 0.38rem;
			}
			.dui_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.48rem;
				.input {
					border-bottom: 0.02rem solid #2568E4;
					input {
						border: none;
						text-align: center;
						font-size: 0.24rem;
						color: #999999;
						padding: 0.12rem 0;
					}
				}
				.fenge {
					color: #2568E4;
					font-size: 0.56rem;
				}
			}
			.button {
				display: flex;
				justify-content: space-between;
				.button_1 {
					color: #2568E4;
					font-size: .36rem;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.35rem;
					height: 0.8rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100% 100%;
					background-image: url(../..//assets/imgs/bag-w.png);
				}
				.button_2 {
					color: #FFFFFF;
					font-size: .36rem;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.35rem;
					height: .8rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100% 100%;
					background-image: url(../..//assets/imgs/bag-b.png);
				}
			}
		}
	}
}

.commonTabtit {
	color: #373737;
	font-size: .24rem;
	height: .8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
	margin-bottom: .2rem;
	.type {
		display: flex;
		align-items: center;
		li {
			line-height: .8rem;
			margin-right: .5rem;
			&.active {
				font-size: .28rem;
				font-weight: bold;
				// border-bottom: 2px solid #90C4FF;
			}
		}
	}
	.img {
		img {
			width: 0.34rem;
			height: 0.34rem;
		}
	}
	.exchange {
		height: .8rem;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		.icon_arr_up {
			width: .1rem;
			height: .1rem;
			border-right: 2px solid #666666;
			border-bottom: 2px solid #666666;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			margin-left: .15rem;
			display: inline-block;
		}
	}
}

// .bill-list-item {
// 	color: #666666;
// 	font-size: .28rem;
// 	background: #F5F5F5;
// 	border-radius: .12rem;
// 	margin-bottom: 0.25rem;
// 	.hd {
// 		padding: .25rem .2rem;
// 		.caption {
// 			height: .4rem;
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			margin-bottom: .25rem;
// 			.yen {
// 				color: #373737;
// 				margin-right: .25rem;
// 			}
// 			.type {
// 				font-size: .24rem;
// 			}
// 		}
// 		.specialFont {
// 			color: #7675D8 !important;
// 			text-transform: uppercase;
// 		}
// 		.info {
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			height: .4rem;
// 			.date {
// 				color: #373737;
// 				font-size: .24rem;
// 			}
// 		}
// 	}
// 	.bd {
// 		color: #373737;
// 		padding: .5rem .2rem  0;
// 		li {
// 			margin-bottom: .25rem;
// 		}
// 		span {
// 			color: #666666;
// 			width: 1.65rem;
// 			display: inline-block;
// 		}
// 	}
// 	.fd {
// 		height: .5rem;
// 		position: relative;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		&::before {
// 			content: '';
// 			position: absolute;
// 			z-index: 1;
// 			left: 0;
// 			right: 0;
// 			top: 50%;
// 			border-top: 1px solid #D8D8D8;
// 		}
// 		img {
// 			width: .24rem;
// 			height: .24rem;
// 			display: block;
// 			background: #F5F5F5;
// 			position: relative;
// 			z-index: 2;
// 			&.up {
// 				-webkit-transform: rotate(180deg);
// 				transform: rotate(180deg);
// 			}
// 		}
// 	}
	
// }
.van-list {
	z-index: -99 !important;
}
.zhezhao {
	// height: 100vh;
	top: 22vh;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;