.aboutOurs .contact {
  padding: 0.35rem 0.5rem;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.aboutOurs .contact .tital {
  font-family: PingFang SC;
  font-size: 0.28rem;
  color: #2B2B2B;
  font-weight: bold;
}
.aboutOurs .contact .message {
  margin-top: 0.3rem;
  color: #666666;
  font-size: 12px;
}
.layoutBox {
  height: calc(100vh - 0.88rem);
  padding: 0.6rem 0.3rem;
  background-color: #F8F8F8;
}
