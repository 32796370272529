


























































































































































































	body {
		background-color: #F5F5F5;
	}
    .billBox {
        .billNumBox{
            border-bottom: 0.2rem solid RGBA(246, 248, 249, 1);
            // padding: 0.2rem 0 0.5rem;
        }
        .billNum {
            text-align: center;
            padding: 0.4rem 0rem 0.6rem 0rem;


            p {
                font-size: 0.27rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #fff;
            }

            span {
                display: block;
                margin-top: 0.2rem;
                font-size: 0.36rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #fff;
            }
        }
		.cumulative{
			background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
			background-size: 100% 100%;
		}
		.today{
			background: url(../../assets/imgs/bill/jryl.png) no-repeat;
			background-size: 100% 100%;
		}
        .billList {
            margin: 0 0.3rem;

            .billItem {
				background: #FFFFFF;
				border-radius: .12rem;
				margin-bottom: .1rem;
                // padding-bottom: 0.26rem;
                // border-bottom: 1px solid #f5f5f5;

                .billTitle {
                    // margin-top: 0.29rem;
                    font-weight: 400;
                    color: #02172E;
					line-height: .4rem;
					padding: .25rem .25rem .15rem;
					border-bottom: 1px solid #EDEDED;

                    i {
                        margin-right: 0.15rem;
                        font-size: 0.29rem;
                        vertical-align: 0.01rem;
                    }

                    span {
                        float: right;
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: 300;
                        color: #B5B4BA;
                        // margin-top: 0.1rem;
                    }
					
					strong {
						font-family: Bahnschrift;
					}
                }

                .billCent {
                    // margin-top: 0.3rem;
                    position: relative;
                    text-align: center;
					padding: .25rem;

                    p {
                        margin-top: 0.12rem;
                        font-size: 0.24rem;
                        color: #8E8AA5;
                    }

                    span {
                        display: block;
                        font-size: 0.28rem;
                        font-family: Bahnschrift;
                        font-weight: 400;
                        color: #02172E;
                        height: 0.3rem;
                    }

                    .billGreen {
                        font-size: 0.33rem;
                        font-family: Bahnschrift;
                        font-weight: 600;
                        color: #23A453;
                    }

                    i {
                        width: 1.5px;
                        height: 0.6rem;
                        background: #F5F5F5;
                        position: absolute;
                        right: 0;
                        top: 0.4rem;
                    }
                }
            }

            .billItem:last-child {
                border-bottom: none;
            }
        }
		
		
    }

// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0 .6rem .2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	background: #FFFFFF;
	box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			color: #666666;
			font-size: .28rem;
			line-height: .4rem;
			width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #FF9872;
				font-size: .52rem;
				font-family: Bahnschrift;
				line-height: .6rem;
				margin-bottom: .1rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	margin-bottom: .25rem;
}
.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	min-height: calc(100vh - 1.8rem);
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;