
















































.aboutOurs {
	.contact {
		padding: 0.35rem 0.5rem;
		background-color: #FFFFFF;
		border-radius: 8px;
		.tital {
			font-family: PingFang SC;
			font-size: 0.28rem;
			color: #2B2B2B;
			font-weight: bold;
		}
		.message {
			margin-top: 0.3rem;
			color: #666666;
			font-size: 12px;
		}
	}
}

// --------20200411[start]------
.layoutBox {
	height: calc(100vh - 0.88rem);
	padding: 0.6rem 0.3rem;
	background-color: #F8F8F8;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;